<template>
  <div>
    <portal to="subheader_portal">
      <router-link :to="{ name: create_url }">
        <b-button variant="success">
          <b-icon icon="plus" aria-hidden="true"></b-icon>
          Nuevo
        </b-button>
      </router-link>
    </portal>
    <div class="row">
      <div class="col-md-12">
        <b-card>
          <b-card-text>
            <div class="col-md-12 mb-4" v-if="hasSearchFilter === true">
              <b-input-group>
                <template #prepend>
                  <b-dropdown :text="searchFilterText" variant="success">
                    <b-dropdown-item
                      @click="setFilter('Cód. barras', 'barcode')"
                      >Cód. barras</b-dropdown-item
                    >
                    <b-dropdown-item
                      @click="setFilter('Nombre', 'canonical_name__icontains')"
                      >Nombre</b-dropdown-item
                    >
                    <b-dropdown-item
                      @click="
                        setFilter('Cod. Proveedor', 'provider_product_id')
                      "
                      >Cod. Proveedor</b-dropdown-item
                    >
                  </b-dropdown>
                </template>
                <b-form-input
                  v-model="q"
                  @keyup.enter="getItems(1)"
                ></b-form-input>
              </b-input-group>
            </div>
            <b-table
              :items="items"
              :fields="fields"
              head-variant="light"
              caption-top
              small
            >
              <template #cell(is_active)="data">
                <toggle-button
                  v-model="data.item.is_active"
                  @change="deactivateItem(data.item)"
                  :value="data.item.is_active"
                  :labels="{ checked: 'ON', unchecked: 'OFF' }"
                  :color="{
                    checked: '#1BC5BD',
                    unchecked: '#F64E60',
                    disabled: '#CCCCCC',
                  }"
                />
              </template>
              <template #cell(actions)="data">
                <b-button
                  size="sm"
                  class="mb-2 mr-2"
                  variant="primary"
                  @click="editItem(data.item)"
                  title="Editar"
                >
                  <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                </b-button>
              </template>
            </b-table>
            <b-pagination
              v-if="is_paginated === true"
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              @change="handlePageChange"
            ></b-pagination>
          </b-card-text>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Utils from "@/core/utils/utils";

export default {
  name: "List",
  props: [
    "model",
    "fields",
    "modelName",
    "backend_url",
    "create_url",
    "is_paginated",
    "hasSearchFilter",
  ],
  data() {
    return {
      searchFilterText: "Filtros",
      items: [],
      currentPage: null,
      rows: null,
      perPage: 10,
      filter: null,
      q: null,
    };
  },
  mounted() {
    this.getItems();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.modelName }]);
  },
  methods: {
    setFilter(text, filter) {
      this.searchFilterText = text;
      this.filter = filter;
    },
    handlePageChange(value) {
      this.page = value;
      this.getItems(value);
    },
    deactivateItem(item) {
      ApiService.delete(`${this.backend_url}/${item.id}/`)
        .then(() => {
          this.$successToast();
        })
        .catch(() => {
          this.$errorToast();
        });
    },
    editItem(item) {
      this.$router.push({
        name: `${this.model}_edit`,
        params: { id: item.id },
      });
    },
    getItems(page = 1) {
      let slug = this.is_paginated === true ? `?page=${page}` : "";
      if (
        Utils.isNotNullOrEmpty(this.filter) &&
        Utils.isNotNullOrEmpty(this.q)
      ) {
        slug += `&filter=${this.filter}&q=${this.q}`;
      }
      ApiService.get(this.backend_url, slug).then((res) => {
        this.items = this.is_paginated === true ? res.data.results : res.data;
        this.rows = this.is_paginated === true ? res.data.count : null;
      });
    },
  },
};
</script>
